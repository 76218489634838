import React from "react"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import AgapeVespers from "../../../static/images/facilities/AgapeVespers.jpg" 
import Chapel from "../../../static/images/facilities/Chapel.jpg" 
import Fall_Church from "../../../static/images/facilities/Fall_Church.jpg" 
import Maranatha_House from "../../../static/images/facilities/Maranatha_House.jpg" 
import SJHPic from "../../../static/images/facilities/SJHPic.jpg" 
import School_Main_Building from "../../../static/images/facilities/School_Main_Building.jpg" 





import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/facilities.css"


function OurParish () {
	const subheaderData = {
		main:"Facilities",
		sub:""
	}
	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content about-us-colors about-us">
				<div>
					<Subheader data={subheaderData} />
				</div>
				<div className="facilities">
					<div className="sjh">
						<h3>The Saint James House</h3>
						<p className="sjh-text">
							The Saint James House is the hospitality center of our community. Upstairs there is a kitchen, a dining room and a large, common living room with a beautiful, stone fireplace. It is used for social gatherings, classes, and a place to greet and visit with guests. The House is overseen by a host family who lives in the main apartment off the kitchen, and it has been the center of a young adult ministry and other ministries for many years.
						</p>
						<img className="sjh-pic left-image" src={SJHPic} />
						<p>
							<b>History: </b> 
							The Saint James House, sometimes called the “Big House,” is the oldest building on the Cathedral property. It was built by a school teacher in 1961 as a private residence. The 800’ deep well that supplied water dried up after the 1964 earthquake and a lack of water became a problem for many years after that. In 1966 the House was willed to the Catholic Church and became home to the sisters of the Monastery of the Precious Blood. In September, 1968 Fr. Thomas Merton stayed on the property in his visit to Alaska and gave talks on community to the nuns. The lack of water led the nuns to sell the property to another owner who let the property fall into disrepair. Harold and Barbara Dunaway were able to purchase the property in September 1972 for $64,000 with the help of a down payment from a visiting Christian speaker, Jack Archer. Situated on 5 acres of land, Harold and Barb’s dream was to start here a ministry modelled after Francis and Edith Schaeffer’s home, L’abri, in Switzerland. For the better part of the next 50 years it has continued to be a home filled with young adults who lived with an overseeing family in order to grow in their Christian Faith. From this work, Saint John’s community blossomed, marriages formed, and eventually our Cathedral parish was established.
						</p>
					</div>
					<div className="cathedral">
						<h3>Saint John Orthodox Cathedral</h3>
						<p className="fall-church-text">
							The Cathedral is the heart of our parish, and the home to its worship and its large community events. There is a large open basement with a kitchen. The Nave, the central worship space, is a large geodesic dome covered in local birch paneling. There are also beautiful stained glass windows and many original Icons. 

						</p>
						<img className="fall-church-img left-image" src={Fall_Church}/>
						<img className="agape-vespers-img right-img" src={AgapeVespers}/>
						<p className="agape-vespers-text">
							<b>History: </b>
							The Cathedral was built as a labor of love by the parishioners of Saint John’s in 1984 and the first service was held there in November 1985. The families in our parish decided to pool together their first Permanent Fund Dividends from the oil revenues of the State of Alaska in order fund the building of the Cathedral. Rotating groups of parishioners worked on weekends to build it. Construction was overseen by Bob Ross and Harley Cranor with the help of craftsman Myles Kelly and (Fr.) Paul Jaroslaw. Design was overseen by (Fr.) Marc Dunaway with help from many others, including Fr. Ted Stylianoplos, who assisted in the final design of the Nave. Fr. Harold Dunaway was pastor at the time, assisted by Fr. Michael Molloy, Wilbur Dittbrender, Tom Ross and Harold Parker. Many talented artists contributed their labor, including iconographer Robin Armstrong, artist Kathy Stone, glass-workers Keith and Anna Haley, craftsman Roy Bloomquist, furniture-maker Ben McNee, and iconographer (Fr.) Luke Dingman. The Cathedral was consecrated by Metropolitan Philip, Archbishop of the Antiochian Archdiocese on March 31, 1987. 
						</p>

					</div>
					<div className="facilities-school left-image">
						<h3>Saint John Orthodox Christian School</h3>
						<p className="school-text">
							Saint John’s School has 60-70 students in grades Preschool through 8th grade, and employs more than 25 staff, including many teachers who job-share and therefore are able to work part-time. The School is open to any who wish to attend and presently half of the students come from families outside our parish. This has enriched our school greatly. In the summer, several classrooms are converted into simple bedrooms for guests who visit our community.
						</p>
						<img className="school-img left-image" src={School_Main_Building}/>
						<p>
							<b>History: </b>
							This building was built in 1974 for provide a wood shop and extra sleeping quarters for young people. The upstairs, which was known as “the Upper Room,” was also the worship center of our growing Church. Our School began in this building in 1980 as a co-op of homeschool parents and soon grew into a traditional school with classrooms and teachers. Today the property rings with the laughter of children as they play at recess and sled down the small hill close by.

						</p>
					</div>
					<div className="chapel">
						<h3>Saint Sergius of Radonezh Chapel</h3>
						<p className="chapel-text">
							Saint Sergius Chapel is built on 50 Acres of land adjacent to the Cathedral property. It is accessible by a half-mile long trail that takes off into the woods across from the Cathedral lawn. It is place for people to visit for prayer. A “Te Deum” service is also held there each year on Thanksgiving Day. Saint Sergius was a spiritual leader and great monastic in Russia in the 14th century. The Chapel altar and its furnishings are refurbished from the Cathedral altar that was burned in an arson fire on August 23, 2012.
						</p>
						<img className="chapel-img left-image" src={Chapel} />
						<p>
							<b>History: </b>
							The land where the Chapel is located was purchased in 1990 from the Eklutna Native Corporation. The Chapel was designed by Yuri and Vera Sidorenko, artists and architects from Russia, who came to stay with their friends Harold and Barbara Parker as they emigrated to the United States. The icons inside were painted by Yuri and blessed by Patriarch Aleksy II of Russia in his visit to Alaska in 1993. The Chapel domes were built by Scott Reese, who with other talented parishioners crafted its decorative woodwork and stained glass windows. 
						</p>
					</div>
					<div className="mnh">
						<h3>The Maranatha House</h3>
						<p className="mnh-text">
							This house sits across the lawn from the Cathedral. It has a first floor apartment that is handicap accessible and can be used to house those with special needs. Upstairs is the “Bishop’s Apartment” and “Deacon’s Rooms” which are used as guest rooms for visiting clergy and others. </p>
						<img className="mnh-img left-image" src={Maranatha_House} />
						<p>
							<b>History: </b>
							The house was built in 1977 to be the residence of Fr. Harold and Barbara Dunaway. It was remodeled in 2005 to make the upstairs guest rooms. Metropolitan Joseph stayed in the Bishop’s apartment when he made his annual Christmas visit to Alaska between 2004 and 2014.
						</p>
					</div>
					<div className="parish-house">
						<h3>The Parish House</h3>
						<p>
							This house sits across Monastery Drive from the Saint James House and next to the cemetery. It is used as home for clergy and others who serve the parish. 
						</p>
					</div>
				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default OurParish 
